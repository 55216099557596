import { Row } from 'antd';

import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import Container from '../../../common/Container';
import { SvgIcon } from '../../../common/SvgIcon';
import {
  Chat, EmptyLarge,
  Extra,
  FooterColumn,
  FooterContainer, FooterRow,
  FooterSection,
  LanguageSwitch,
  LanguageSwitchContainer,
  Large,
  LogoContainer,
  NavLink,
  Para,
  Title,
} from './styles';

interface SocialLinkProps {
  href: string;
  src: string;
}

interface FooterContentProps {
  title: string;
  columns: any[];
  languages: any[];
  t: any;
}

const Footer = ({ title, columns, languages, t }: FooterContentProps) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" key={src} aria-label={src}>
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <FooterRow>
            {
              columns.map((column, id) => (
                <FooterColumn key={id}>
                  <Title>{t(column.title)}</Title>
                  { column.large ? <Large to="">{t(column.large)}</Large> : <EmptyLarge />}
                  { column.parameters && column.parameters.map((param: string, paramId: number) => (
                    <Para key={paramId}>{t(param)}</Para>
                  ))}
                  {column.link && <a href={column.link.href} target="_blank">
                    <Chat>{t(column.link.text)}</Chat>
                  </a>
                  }
                </FooterColumn>
              ))
            }
            <FooterColumn>
              <Title>{t('Languages')}</Title>
              <LanguageSwitchContainer>
                {
                  languages.map((language, id) => (
                    <LanguageSwitch key={id} onClick={() => handleChange(language.language)}>
                      <SvgIcon src={language.icon} aria-label="homepage" width="30px" height="30px" />
                    </LanguageSwitch>
                  ))
                }
              </LanguageSwitchContainer>
            </FooterColumn>
          </FooterRow>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row justify="space-between" align="middle" style={{ paddingTop: '3rem' }}>
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon src="logo.svg" width="auto" height="64rem"/>
                <h5>{title}</h5>
              </LogoContainer>
            </NavLink>

            <FooterContainer>
              <span>{'Made with 💙 by Víctor Igualada'}</span>
              <SocialLink href="https://github.com/victorigualada" src="github.svg" />
              <SocialLink href="https://www.linkedin.com/in/victorigualada/" src="linkedin.svg" />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
