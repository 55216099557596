import * as React from 'react';
import { lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Footer from '../components/Content/Footer';
import FooterContent from '../content/FooterContent.json';
import { Styles } from '../styles/styles';
import routes from './config';

const Router = () => {
  const location = useLocation();
  return (
    <Suspense fallback={null}>
      <Styles />
      <Routes>
        {routes.map((routeItem) => {
          const MenuElement = React.createElement(lazy(() => import(`../pages/${routeItem.component}`)));
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              element={MenuElement}
            />
          );
        })}
      </Routes>
      {
        location.pathname !== '/admin'
          ? <Footer
            title={FooterContent.title}
            columns={FooterContent.columns}
            languages={FooterContent.languages}
          />
          : ''
      }
    </Suspense>
  );
};

export default Router;
