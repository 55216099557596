import { createGlobalStyle } from 'styled-components';

export const Styles = createGlobalStyle`
  :root {
    --color-primary: #ec4755;
    --color-secondary: #a12c34;
    --color-tertiaty: #6B7280;
    --color-black: #111827;
    --color-gray: #4B5563;
    --font-family:  ui-sans-serif,
                    system-ui,
                    -apple-system,
                    BlinkMacSystemFont,
                    Segoe UI,
                    Roboto,
                    Helvetica Neue,
                    Arial,
                    Noto Sans,
                    sans-serif,
                    Apple Color Emoji,
                    Segoe UI Emoji,
                    Segoe UI Symbol,
                    Noto Color Emoji;
    --screen-xs: 576px;
  }

  @font-face {
    font-family: var(--font-family);
    font-style: normal;
  }

  @font-face {
    font-family: var(--font-family);
    font-style: normal;
  }


  body,
  html,
  a {
    font-family: var(--font-family);, sans-serif;
  }


  body {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: #fff;
    overflow-x: hidden;
  }

  a:hover {
    color: var(--color-secondary);
  }

  input,
  textarea {
    border-radius: 4px;
    border: 0;
    background: rgb(241, 242, 243);
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    padding: 1rem 1.25rem;

    :focus-within {
      background: none;
      box-shadow: var(--color-secondary) 0px 0px 0px 1px;
    }
  }

  h1,
  h2 {
    font-family: var(--font-family);
    color: var(--color-primary);
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 800;
    letter-spacing: -.025em;

    @media only screen and (max-width: 576px) {
      font-size: 2.25rem;
    }
  }
  
  h3 {
    font-family: var(--font-family);
    color: var(--color-black);
    font-size: 4rem;
    font-weight: 800;
    letter-spacing: -.025em;

    @media only screen and (max-width: 576px) {
      font-size: 1.25rem;
    }
  }
  
  h4 {
    font-family: var(--font-family);
    color: var(--color-primary);
    font-size: 1rem;
    text-transform: uppercase;
    line-height: 1rem;
    font-weight: 600;
    letter-spacing: .025em;

    @media only screen and (max-width: 960px) {
      font-size: 1rem;
      text-align: left;
    }
  }
  
  h5 {
    font-family: var(--font-family);
    color: var(--color-black);
    font-size: 2.25rem;
    line-height: 2rem;
    font-weight: 600;
    letter-spacing: -.025em;

    @media only screen and (max-width: 960px) {
      font-size: 1.875rem;
      text-align: left;
    }
  }

  p {
    color: var(--color-tertiaty);
    color: var(--color-tertiaty);
    font-size: 1.25rem;

    @media only screen and (max-width: 576px) {
      font-size: 1rem;
    }
  }

  a {
    text-decoration: none;
    outline: none;
    color: var(--color-tertiaty);

    :hover {
      color: var(--color-secondary);
    }
  }

  *:focus {
    outline: none;
  }

  .about-block-image svg {
    text-align: center;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 1.5rem;
  }

  .MuiSvgIcon-root.MuiStepIcon-root.Mui-active,
  .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed {
    color: var(--color-primary) !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: var(--color-secondary) !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-secondary) !important;
  }

   .admin-logo {
     background-color: #fff;
     padding: 1rem 1rem;
     display: flex;
     justify-content: space-between;
     max-height: 4rem;
   }

   .site-layout .site-layout-background {
     background: #fff;
   }
`;
