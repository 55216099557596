const routes = [
  {
    path: '/',
    component: 'Home'
  },
  {
    path: '/pricing',
    component: 'Home'
  },
  {
    path: '/product',
    component: 'Home'
  },
  {
    path: '/features',
    component: 'Home'
  },
  {
    path: '/booking',
    component: 'Booking'
  },
  {
    path: '/admin',
    component: 'Admin'
  },
  {
    path: '/about',
    component: 'About'
  }
];

export default routes;
